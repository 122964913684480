import React, { ReactNode } from "react";
import dynamic from "next/dynamic";
import { toast as toastFn, ToastOptions, Id, ToastContentProps } from "react-toastify";

import { TOAST_TYPE } from "./constants";

const Toast = dynamic(() => import("components/Toast"), { ssr: false });

export enum ToastActionType {
  LINK = "link",
  BUTTON = "button"
}
export type ToastAction = {
  link?: string;
  type: ToastActionType;
  onClick?: () => void;
  label?: string;
};
type ToastOptionsWithDescription = ToastOptions & { description?: string; actions?: ToastAction[] };

const className = "bg-neutral-0 dark:bg-neutral-dark-0 shadow-100 !rounded-lg m-4 lg:m-0";

export type ToastId = Id;

const toast = {
  success: (title: string, options: ToastOptionsWithDescription = {}) =>
    toastFn(
      <Toast title={title} description={options.description} actions={options.actions} type={TOAST_TYPE.SUCCESS} />,
      {
        autoClose: 3000,
        ...options,
        className
      }
    ),
  error: (title: string, options: ToastOptionsWithDescription = {}) =>
    toastFn(
      <Toast title={title} description={options.description} actions={options.actions} type={TOAST_TYPE.ERROR} />,
      {
        autoClose: 3000,
        ...options,
        className
      }
    ),
  update: (toastId: ToastId, newTitle?: string, { type, ...options }: ToastOptions = {}) =>
    toastFn.update(toastId, {
      render: newTitle ? <Toast title={newTitle} type={(type || TOAST_TYPE.SUCCESS) as TOAST_TYPE} /> : undefined,
      autoClose: 3000,
      ...options
    }),
  updateWithHtml: (
    toastId: ToastId,
    newContent?: ({ closeToast }: ToastContentProps) => ReactNode,
    options: ToastOptions = {}
  ) =>
    newContent
      ? toastFn.update(toastId, {
          ...options,
          render: newContent
        })
      : undefined,
  dismiss: toastFn.dismiss
};

export default toast;
