"use client";

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/react";
import { Menu as MenuIcon, X } from "lucide-react"; // Rename the Menu to MenuIcon to avoid the conflict
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import Logo from "components/logo";
import useScroll from "hooks/useScroll";
import useUIState from "hooks/useUIState";
import {
  PAGE_QUIZ_CTA_BUTTON_MAP,
  PAGE_QUIZ_DEFAULT_CTA_BUTTON,
  PAGE_QUIZ_DEFAULT_CTA_BUTTON_SPANISH
} from "components/Quiz/utils";
import { APP_LANGUAGES, APP_STRINGS_LABELS, getAppStringForLanguage } from "utils/language";
import { mobileMenuNavItems, OverlayedWebsiteNavPages, spanishMobileMenuNavItems } from "./utils";

export default function MobileMenu() {
  // <-- Remove the menu prop here
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const openMobileMenu = () => setIsOpen(true);
  const closeMobileMenu = () => setIsOpen(false);
  const scrolled = useScroll(5);
  const isOverlayed = pathname && OverlayedWebsiteNavPages.includes(pathname);
  const { uiState } = useUIState();
  const quizCtaButtonInfo =
    pathname && PAGE_QUIZ_CTA_BUTTON_MAP[pathname]
      ? PAGE_QUIZ_CTA_BUTTON_MAP[pathname]
      : uiState?.websiteTileLanguage === APP_LANGUAGES.SPANISH
        ? PAGE_QUIZ_DEFAULT_CTA_BUTTON_SPANISH
        : PAGE_QUIZ_DEFAULT_CTA_BUTTON;

  const language = uiState?.websiteTileLanguage || APP_LANGUAGES.ENGLISH;
  const finalNavItems = language === APP_LANGUAGES.SPANISH ? spanishMobileMenuNavItems : mobileMenuNavItems;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname, searchParams]);

  return (
    <>
      <button
        onClick={openMobileMenu}
        aria-label="Open mobile menu"
        className={clsx(
          "flex h-11 w-11 items-center justify-center rounded-md transition-colors lg:hidden",
          isOverlayed && !scrolled ? "text-white" : "text-primary-700 "
        )}
      >
        <MenuIcon size={25} />
      </button>
      <Transition show={isOpen}>
        <Dialog onClose={closeMobileMenu} className="relative z-50">
          <TransitionChild
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="opacity-0 backdrop-blur-none"
            enterTo="opacity-100 backdrop-blur-[.5px]"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="opacity-100 backdrop-blur-[.5px]"
            leaveTo="opacity-0 backdrop-blur-none"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="translate-y-[-100%]"
            enterTo="translate-y-0"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-[-100%]"
          >
            <DialogPanel className="fixed bottom-0 left-0 right-0 top-0 mx-auto flex h-full w-full flex-col  bg-[#fffdf6]  ">
              <div className="tiles-bg-background flex h-20 items-center justify-between border-b-gray-150 px-6 lg:px-20">
                <Link href="/" title="Sold Ave">
                  <Logo />
                </Link>
                <a
                  href={`tel:${getAppStringForLanguage(APP_STRINGS_LABELS.SOLDAVE_NUMBER_E164, language)}`}
                  className="mr-2 text-sm font-medium text-primary-700"
                  title={getAppStringForLanguage(APP_STRINGS_LABELS.CALL_SOLDAVE, language)}
                >
                  {getAppStringForLanguage(APP_STRINGS_LABELS.SOLDAVE_NUMBER, language)}
                </a>
                <button
                  className="flex h-11 w-11 items-center justify-center rounded-md border border-neutral-200 text-black transition-colors  md:hidden"
                  onClick={closeMobileMenu}
                  aria-label="Close mobile menu"
                >
                  <X size={25} />
                </button>
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-[#cce9d3]">
                <Link
                  href={`/${getAppStringForLanguage(APP_STRINGS_LABELS.QUIZ, language)}/${quizCtaButtonInfo.slug}`}
                  prefetch={false}
                  className="text-primary flex items-center justify-center gap-x-2.5 px-3 py-4 font-semibold hover:bg-gray-100"
                >
                  {quizCtaButtonInfo?.ctaText}
                </Link>
              </div>
              <div className="p-6">
                {finalNavItems.length ? (
                  <ul className="flex w-full flex-col">
                    {finalNavItems.map(({ name, slug, dropdown, items }) => (
                      <li className="py-3 text-xl" key={name}>
                        {dropdown ? (
                          <Disclosure>
                            <DisclosureButton className="text-xl font-normal text-primary-700">{name}</DisclosureButton>
                            {items && (
                              <DisclosurePanel className="pt-3">
                                <ul className="flex w-full flex-col gap-2 pl-4 text-md font-normal">
                                  {items.map(({ name, slug }) => (
                                    <li key={name} className="py-1">
                                      <Link href={slug} onClick={closeMobileMenu}>
                                        {`${name}-test`}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </DisclosurePanel>
                            )}
                          </Disclosure>
                        ) : (
                          <Link href={slug} onClick={closeMobileMenu}>
                            {name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </DialogPanel>
          </TransitionChild>
        </Dialog>
      </Transition>
    </>
  );
}
