"use client";

import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "usehooks-ts";
import { XIcon } from "lucide-react";

import IconButton from "components/IconButton";

const ToastWrapper = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <ToastContainer
      closeButton={({ closeToast }) => (
        <IconButton icon={XIcon} color="transparent" size="xs" className="m-1 ml-auto" onClick={closeToast} />
      )}
      position={isMobile ? "bottom-center" : "top-right"}
      hideProgressBar
    />
  );
};

export default ToastWrapper;
