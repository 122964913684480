"use client";
import React, { useEffect, useMemo, useState } from "react";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { FALLBACK_IMAGE_SRC } from "utils/constants";
import { buildSupabasePublicUrl, getBlurUrl } from "./utils";

export type ImageProps = NextImageProps & {
  src: string;
  alt: string;
  mRef?: React.Ref<HTMLImageElement>;
  onLoadingComplete?: (result: HTMLImageElement) => void;
};

const ImageComponent = ({
  src = "",
  alt = "",
  mRef,
  onLoadingComplete,
  blurDataURL = getBlurUrl(),
  priority,
  fill,
  ...rest
}: ImageProps) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = useMemo(() => {
    const isExternal = src.startsWith("http") || src.startsWith("blob");
    return error ? buildSupabasePublicUrl(FALLBACK_IMAGE_SRC) : isExternal ? src : buildSupabasePublicUrl(src);
  }, [src, error]);

  return (
    <>
      {isExternal ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          ref={mRef}
          src={finalSrc}
          placeholder="blur"
          alt={alt}
          quality={80}
          onError={() => {
            setError(true);
          }}
          {...rest}
        />
      ) : (
        <NextImage
          ref={mRef}
          src={finalSrc}
          blurDataURL={blurDataURL}
          placeholder="blur"
          alt={alt}
          quality={80}
          priority={priority}
          fill={fill}
          onLoadingComplete={(result) => {
            if (result.naturalWidth === 0) {
              // broken image
              setError(true);
            }
            onLoadingComplete?.(result);
          }}
          onError={() => {
            setError(true);
          }}
          {...rest}
        />
      )}
    </>
  );
};

ImageComponent.displayName = "Image";

export default ImageComponent;
