import React from "react";
import Link from "next/link";
import clsx from "clsx";
import noop from "lodash/noop";
import { LucideIcon, Loader as SpinnerIcon } from "lucide-react";
import { ButtonSize, ButtonIconPosition, ButtonAppearance } from "utils/constants";
import Skeleton from "components/Skeleton";
import IconWithName from "components/Icon";
import { RecordItem } from "types/common";

type ButtonProps = {
  label: string;
  size?: ButtonSize;
  icon?: LucideIcon | ((props: any) => JSX.Element) | null;
  iconName?: string;
  iconPosition?: ButtonIconPosition;
  appearance?: ButtonAppearance;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  isLoading?: boolean;
  isDataLoading?: boolean;
  as?: "link" | "link-internal";
  disabled?: boolean;
  count?: number;
  classNameIcon?: string;
  showCountIndicator?: boolean; // to show the count indicator but with no count value
  href?: string;
  additionalProps?: RecordItem;
  ref?: React.Ref<HTMLButtonElement>;
};

const clsSpinnerSize = {
  [ButtonSize.XS]: "h-2 w-2",
  [ButtonSize.SM]: "h-3 w-3",
  [ButtonSize.MD]: "h-5 w-5"
};
const clsButtonSpacing = {
  [ButtonSize.XS]: "px-3 h-9 py-2 text-sm font-medium",
  [ButtonSize.SM]: "px-4 h-8 text-sm font-medium",
  [ButtonSize.MD]: "px-[1.40625rem] h-14 text-md font-medium"
};

const clsButtonStyling = {
  [ButtonAppearance.PRIMARY]: clsx(
    "text-sm font-medium transition-colors bg-[#171717] dark:bg-[#5E69D1] text-[#ffffff]",
    "hover:bg-[#383838] dark:hover:bg-[#4A57CB] active:bg-primary-900"
  ),
  [ButtonAppearance.SECONDARY]: clsx(
    "text-primary border border-neutral-300 hover:bg-neutral-200 active:bg-neutral-300 shadow-50 hover:shadow-none active:shadow-none",
    "dark:border-neutral-dark-300 dark:hover:bg-neutral-dark-200 dark:active:bg-neutral-dark-300"
  ),
  [ButtonAppearance.RED]: "text-neutral-0 bg-red-700 hover:bg-red-800 active:bg-red-900",
  [ButtonAppearance.TERTIARY]: "text-primary-700 bg-transparent hover:bg-primary-50 underline underline-offset-2",
  [ButtonAppearance.NEUTRAL]: "text-primary bg-neutral-200 hover:bg-neutral-200 active:bg-neutral-200"
};

const clsIconSize: { [key: string]: string } = {
  [ButtonSize.XS]: "h-4 w-4",
  [ButtonSize.SM]: "h-4 w-4",
  [ButtonSize.MD]: "h-4 w-4"
};
const classNamePosition = {
  [ButtonIconPosition.LEFT]: "-ml-0",
  [ButtonIconPosition.RIGHT]: "ml-2 -mr-1"
};
const clsIconAppearance = {
  [ButtonAppearance.PRIMARY]: "text-neutral-0",
  [ButtonAppearance.SECONDARY]: "text-primary",
  [ButtonAppearance.RED]: "text-neutral-0",
  [ButtonAppearance.TERTIARY]: "text-primary",
  [ButtonAppearance.NEUTRAL]: "text-primary"
};

const RenderedIcon = ({
  icon,
  iconName,
  iconPosition,
  size,
  disabled,
  appearance,
  classNameIcon
}: {
  icon?: LucideIcon | ((props: any) => JSX.Element) | null;
  iconName?: string;
  iconPosition?: ButtonIconPosition;
  size: ButtonSize;
  appearance: ButtonAppearance;
  disabled?: boolean;
  classNameIcon?: string;
}) => {
  if (!icon && !iconName) return null;

  if (iconName) {
    return (
      <IconWithName
        name={iconName as any}
        className={clsx(
          classNamePosition[iconPosition || ButtonIconPosition.LEFT],
          clsIconSize[size],
          disabled ? "text-base-disabled dark:text-base-dark-disabled" : clsIconAppearance[appearance],
          classNameIcon
        )}
      />
    );
  }

  if (icon) {
    const IconComponent = icon;

    return (
      <IconComponent
        className={clsx(
          classNamePosition[iconPosition || ButtonIconPosition.LEFT],
          clsIconSize[size],
          clsIconAppearance[appearance],
          classNameIcon
        )}
        aria-hidden="true"
      />
    );
  }
};

const Button = ({
  label = "",
  size = ButtonSize.XS,
  icon,
  iconName,
  iconPosition = ButtonIconPosition.LEFT,
  appearance = ButtonAppearance.PRIMARY,
  onClick = noop,
  isLoading = false,
  isDataLoading = false,
  className = "",
  classNameIcon = "",
  as,
  disabled,
  count,
  showCountIndicator = false,
  additionalProps,
  ref,
  ...rest
}: ButtonProps) => {
  if (isDataLoading) {
    return <Skeleton className={clsButtonSpacing[size]} />;
  }

  if (as === "link-internal") {
    return (
      <Link
        data-testid="Button"
        className={clsx(
          "text-primary inline-flex scale-100 items-center justify-center gap-x-1 rounded-md transition-transform active:scale-[0.98]",
          clsButtonSpacing[size],
          clsButtonStyling[appearance],
          className,
          !disabled && "cursor-pointer",
          disabled &&
            "!bg-neutral-200 !text-base-disabled !shadow-none dark:!bg-neutral-dark-200 dark:!text-base-dark-disabled"
        )}
        href={additionalProps?.href || ""}
        {...additionalProps}
        {...rest}
      >
        <>
          {(icon || iconName) && !isLoading && iconPosition === ButtonIconPosition.LEFT ? (
            <RenderedIcon
              icon={icon}
              iconName={iconName}
              iconPosition={iconPosition}
              size={size}
              appearance={appearance}
              disabled={disabled}
              classNameIcon={classNameIcon}
            />
          ) : null}
          {isLoading && <SpinnerIcon className={clsx(clsSpinnerSize, "-ml-1 mr-2 animate-spin")} />}
          {label}
          {(icon || iconName) && !isLoading && iconPosition === ButtonIconPosition.RIGHT ? (
            <RenderedIcon
              icon={icon}
              iconName={iconName}
              iconPosition={iconPosition}
              size={size}
              appearance={appearance}
              disabled={disabled}
              classNameIcon={classNameIcon}
            />
          ) : null}
          {(!!count || showCountIndicator) && (
            <span
              className={clsx(
                "absolute right-[-8px] top-[-8px] flex h-4 w-4 items-center justify-center rounded-full bg-red-700 text-[8px] font-semibold text-white dark:bg-red-dark-700"
              )}
            >
              {count}
            </span>
          )}
        </>
      </Link>
    );
  }

  if (as === "link") {
    return (
      <a
        data-testid="Button"
        className={clsx(
          "text-primary inline-flex scale-100 items-center justify-center gap-x-1 rounded-md transition-transform active:scale-[0.98]",
          clsButtonSpacing[size],
          clsButtonStyling[appearance],
          className,
          !disabled && "cursor-pointer",
          disabled &&
            "!bg-neutral-200 !text-base-disabled !shadow-none dark:!bg-neutral-dark-200 dark:!text-base-dark-disabled"
        )}
        {...additionalProps}
        {...rest}
      >
        <>
          {(icon || iconName) && !isLoading && iconPosition === ButtonIconPosition.LEFT ? (
            <RenderedIcon
              icon={icon}
              iconName={iconName}
              iconPosition={iconPosition}
              size={size}
              appearance={appearance}
              disabled={disabled}
              classNameIcon={classNameIcon}
            />
          ) : null}
          {isLoading && <SpinnerIcon className={clsx(clsSpinnerSize, "-ml-1 mr-2 animate-spin")} />}
          {label}
          {(icon || iconName) && !isLoading && iconPosition === ButtonIconPosition.RIGHT ? (
            <RenderedIcon
              icon={icon}
              iconName={iconName}
              iconPosition={iconPosition}
              size={size}
              appearance={appearance}
              disabled={disabled}
              classNameIcon={classNameIcon}
            />
          ) : null}
          {(!!count || showCountIndicator) && (
            <span
              className={clsx(
                "absolute right-[-8px] top-[-8px] flex h-4  w-4 items-center justify-center rounded-full bg-red-700 text-[8px] font-semibold text-white dark:bg-red-dark-700"
              )}
            >
              {count}
            </span>
          )}
        </>
      </a>
    );
  }

  return (
    <button
      data-testid="Button"
      className={clsx(
        "text-primary inline-flex scale-100 items-center justify-center gap-x-1 rounded-md transition-transform active:scale-[0.98]",
        clsButtonSpacing[size],
        clsButtonStyling[appearance],
        className,
        !disabled && "cursor-pointer",
        disabled &&
          "!bg-neutral-200 !text-base-disabled !shadow-none dark:!bg-neutral-dark-200 dark:!text-base-dark-disabled"
      )}
      onClick={onClick}
      disabled={disabled}
      {...(ref ? { ref } : {})}
      {...rest}
    >
      <>
        {(icon || iconName) && !isLoading && iconPosition === ButtonIconPosition.LEFT ? (
          <RenderedIcon
            icon={icon}
            iconName={iconName}
            iconPosition={iconPosition}
            size={size}
            appearance={appearance}
            disabled={disabled}
            classNameIcon={classNameIcon}
          />
        ) : null}
        {isLoading && <SpinnerIcon className={clsx(clsSpinnerSize, "-ml-1 mr-2 animate-spin")} />}
        {label}
        {(icon || iconName) && !isLoading && iconPosition === ButtonIconPosition.RIGHT ? (
          <RenderedIcon
            icon={icon}
            iconName={iconName}
            iconPosition={iconPosition}
            size={size}
            appearance={appearance}
            disabled={disabled}
            classNameIcon={classNameIcon}
          />
        ) : null}
        {(!!count || showCountIndicator) && (
          <span
            className={clsx(
              "absolute right-[-8px] top-[-8px] flex h-4  w-4 items-center justify-center rounded-full bg-red-700 text-[8px] font-semibold text-white dark:bg-red-dark-700"
            )}
          >
            {count}
          </span>
        )}
      </>
    </button>
  );
};

Button.displayName = "Button";

export default Button;
