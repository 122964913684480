import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";

import { ApiQuiz, Quiz } from "components/Quiz/quizTypes";
import { mapApiQuizToQuiz } from "components/Quiz/utils";
import type {
  ActionResponse,
  ActionsResponse,
  AlgoliaSearchTableResponse,
  AlgoliaSearchTablesResponse,
  ApiAlgoliaSearchTable,
  ApiColumnSection,
  ApiPage,
  ApiPageViews,
  ApiRecordType,
  ApiView,
  BAPageResponse,
  BAPagesResponse,
  ColorsResponse,
  ColumnTemplateResponse,
  ComponentsResponse,
  CustomIconResponse,
  CustomViewsResponse,
  FilterTemplateResponse,
  MenuItemsResponse,
  MenuResponse,
  MenusResponse,
  PagesResponse,
  RecordTypesResponse,
  ReviewsResponse,
  TableViewConfigResponse,
  TableViewConfigsResponse,
  TileComponentsResponse,
  Tiles,
  TilesResponse
} from "types/apiTypes";
import { Page, PaginationState, TableColumnType, TableFilterType, ViewSection } from "types/baTypes";
import { PAGE_QUERY_ADDITIONAL_PROP_TYPES, RecordItem } from "types/common";
import {
  CACHED_PAGES_IDS,
  CACHED_PAGES_SLUGS,
  SA_CACHE_KEYS,
  TILE_DISABLE_PAGE_RENDER,
  USER_TYPE,
  ViewOption
} from "utils/constants";
import {
  mapApiAlgoliaSearchTableToAlgoliaSearchTable,
  mapApiColumnSectionsToSections,
  mapApiPageToPage,
  mapApiPageViewsToPageViews,
  mapApiViewToTableViewConfig
} from "utils/pageUtils";
import { constructSupabaseSelect } from "./utils";

const IS_LOCAL_DEV = !process.env.NEXT_PUBLIC_VERCEL_URL;
const IS_VERVEL_PREVIEW = process.env.VERCEL_ENV === "preview";

const getFinalUser = (peopleData: RecordItem) => {
  const user = peopleData?.[0];
  const featuredImg = user?.featured_file_id;
  if (featuredImg) {
    user.file = featuredImg;
  }

  if (user?.workspace?.name) {
    user.workspaceName = user.workspace.name;
  }
  return user;
};

export const getCurrentUser = async (supabaseClient: SupabaseClient, userId: string) => {
  const select =
    "*,id,workspace:workspace_id(id,name,allowed_user_types),featured_file_id(id,path,file_type),roles:contacts_roles(id,role_id)";
  const { data, error } = await supabaseClient.from("contacts").select(select).eq("user_id", userId);

  return { data: data?.[0] ? getFinalUser(data) : null, error };
};

export const getAllMenuItems = async (
  supabaseClient: SupabaseClient,
  menuId: string,
  workspaceIds?: string[]
): Promise<MenuItemsResponse> => {
  if (!workspaceIds?.length) {
    return { data: [], error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_menu_items")
    .select(
      "*, menuItemRoles:ui_menu_items_roles(id,role_id), ui_page_id(id, title, path, table_name, metabase_dashboard_id, default_view, mobile_default_view, page_type, is_admin_page), ui_menu_actions(id, menuActionRoles:ui_menu_actions_roles(id,role_id), action_properties, ui_action_id(id, name, type, icon), wait_for_success, has_divider, sort)"
    )
    .eq("is_deleted", false)
    .eq("ui_menu_id", menuId)
    .in("workspace_id", workspaceIds);
  return { data, error };
};

export const getPagesForAdmin = async (
  supabaseClient: SupabaseClient,
  userType?: USER_TYPE,
  workspaceIds?: string[]
): Promise<BAPagesResponse> => {
  if (!userType || !workspaceIds) {
    return { data: [], error: null };
  }
  let supabaseSelect = supabaseClient
    .from("pages")
    .select(
      `id,workspace_id,
      pageFilters:page_filters_id(id,
        columnRules:ui_column_condition(id,group_condition_id,
          colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),
          pageColumn:ui_page_column_id(*),
          field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
        )
      ),
      page_config,menu_id,is_deleted,created_in_path,default_view,has_error,default_empty_view,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,
      page_type,parent_id,path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type,
      columns:ui_pages_columns(*,
        column:columns_id(*,ui_pages_views_columns(*,
          view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,is_deleted))))),
      views:ui_pages_views(id,default_section_id,record_default_section_id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*),pageViewRoles:ui_pages_views_roles(id,role_id),
        conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)), 
        tabs:ui_pages_views_tabs(
        id,created_at,is_child,is_hidden, page_view_id,tab:ui_tabs(
          id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)))
      `
    )
    .eq("views.view.is_deleted", false)
    .eq("is_deleted", false)
    .or(`workspace_id.in.(${workspaceIds}),is_admin_page.eq.true`);
  if (userType !== USER_TYPE.STAFF) {
    supabaseSelect = supabaseSelect.eq("user_type", userType);
  }
  const { data, error } = await supabaseSelect;
  // @ts-ignore
  return { data: data?.map((page) => mapApiPageToPage(page)), error };
};

export const getPagesLite = async (
  supabaseClient: SupabaseClient,
  userType?: USER_TYPE,
  workspaceIds?: string[],
  onlyFetchBaseFields?: boolean
): Promise<BAPagesResponse> => {
  if (!userType || !workspaceIds?.length) {
    return { data: [], error: null };
  }
  let select = `id,workspace_id, page_config,menu_id,is_deleted,created_in_path,is_default_table_page,default_view,has_error,default_empty_view,head_title,is_admin_page,
      metabase_dashboard_id,mobile_default_view,
      page_type,parent_id,path,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type`;

  if (!onlyFetchBaseFields) {
    select += `,pageFilters:page_filters_id(id,
        columnRules:ui_column_condition(
          id,group_condition_id,pageColumn:ui_page_column_id(
            *, column:columns_id(id)
          ),
          field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
        )
      ),
      views:ui_pages_views(id,pages_id,default_section_id,record_default_section_id,is_custom,pageViewRoles:ui_pages_views_roles(id,role_id),
        view:views_id(id,view_type,is_deleted,is_default,additional_config),
        conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columns:ui_pages_views_columns(id),
        tabs:ui_pages_views_tabs(id,page_view_id,ui_tab_id,tab:ui_tabs(id,tab_page_id(id,path),add_page_id))
      )
      `;
  }

  let supabaseClientRef = supabaseClient
    .from("pages")
    .select(select)
    .eq("is_deleted", false)
    .in("workspace_id", workspaceIds);

  if (!onlyFetchBaseFields) {
    supabaseClientRef = supabaseClientRef.eq("views.view.is_deleted", false);
  }
  if (userType !== USER_TYPE.STAFF) {
    supabaseClientRef = supabaseClientRef.eq("user_type", userType);
  }
  const { data, error } = await supabaseClientRef;

  // @ts-ignore
  return { data: data?.map((page) => mapApiPageToPage(page)), error };
};

export const getDefaultTablePages = async (supabaseClient: SupabaseClient, workspaceId?: string) => {
  if (!workspaceId) {
    return { data: [], error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select("id,path,title,table_name,is_default_table_page,workspace_id")
    .eq("is_deleted", false)
    .eq("is_default_table_page", true)
    .eq("workspace_id", workspaceId);
  return { data, error };
};

export const getPagesColumnsByIds = async ({
  supabaseClient,
  pageIds,
  additionalDataProps,
  workspaceId
}: {
  supabaseClient: SupabaseClient;
  pageIds: string[];
  additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
  workspaceId?: string;
}): Promise<BAPagesResponse> => {
  if (!pageIds?.length || !workspaceId) {
    return { data: [], error: null };
  }

  let select = `id,is_deleted,page_config,table_name,title,user_type,
  pageFilters:page_filters_id(
    id,
    columnRules:ui_column_condition(id,group_condition_id,
      pageColumn:ui_page_column_id(
        *,
        column:columns_id(id)
      ),
      field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
    )
  ),
  columns:ui_pages_columns(*,
    lookupFilters:lookup_filter_id(
      id,
      columnRules:ui_column_condition(
        id,group_condition_id,
        pageColumn:ui_page_column_id(*,
          column:columns_id(id)
        ),
        field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
      )
    ),
    column:columns_id(
      *,
      ui_pages_views_columns(
        *,
        view:pages_views_id(
          id,is_custom,pages_id,
          views_id(id,view_type,is_default)
        )
      )
    )
  )
  `;

  if (additionalDataProps?.includes("column_rules")) {
    select +=
      ",conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))";
  }
  if (additionalDataProps?.includes("sections")) {
    select += `,views:ui_pages_views(id,is_custom,default_section_id,record_default_section_id,pageViewRoles:ui_pages_views_roles(id,role_id),
          sections:ui_sections!public_ui_column_sections_page_view_id_fkey(id,
            sectionRoles:ui_sections_roles(id,role_id),
            title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
            pageViewColumns:ui_pages_views_column_sections(id,
              ui_pages_views_columns_id(columns_id),
              columns:ui_pages_views_columns(*,
                column:columns_id(id)
              )
            ),
            conditionalViewRules:conditional_view_rule_id(id,rule_config,
            columnRules:ui_column_condition(id,group_condition_id,
              colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),
              pageColumn:ui_page_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          )
          ),
          view:views_id(*)
          )
        `;
  } else {
    select += `,
    views:ui_pages_views(id,is_custom,default_section_id,record_default_section_id,
        view:views_id(*),
        columns:ui_pages_views_columns(*,column:columns_id(id)))`;
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(select)
    .eq("views.view.is_deleted", false)
    .eq("workspace_id", workspaceId)
    .in("id", pageIds);

  if (data?.length) {
    return {
      // @ts-ignore
      data: data?.map((page) => mapApiPageToPage(page)),
      error
    };
  }
  // @ts-ignore
  return { data, error };
};

export const getPageByPath = async (
  supabaseClient: SupabaseClient,
  path: string,
  userType: USER_TYPE,
  source?: string,
  workspaceIds?: string[],
  skipWorkspaceIdCheck?: boolean,
  pickDefault?: boolean
): Promise<BAPageResponse> => {
  if (!path || !userType || (!skipWorkspaceIdCheck && !workspaceIds?.length)) {
    // @ts-ignore
    return { data: null, error: null };
  }

  const isCachedPage = CACHED_PAGES_SLUGS.includes(path);
  const cachedPageKey = `${SA_CACHE_KEYS.PAGE_DATA_BY_PATH}${path}-${userType}-${!!pickDefault}`;

  // check if page is cachedPage and fetch from cache and return if present
  if (isCachedPage) {
    const cachedValue = await getSACachedValue(cachedPageKey, supabaseClient);
    if (cachedValue?.data?.value) {
      return {
        data: cachedValue?.data?.value as Page,
        error: null,
        count: null as any,
        status: 200,
        statusText: ""
      };
    }
  }

  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,workspace_id,is_deleted,page_config,menu_id,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,
      parentPage:parent_id(id,path),path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type,
      pageFilters:page_filters_id(id,
        columnRules:ui_column_condition(id,colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,group_condition_id,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,is_user_visible,pageColumn:ui_page_column_id(*),field,filter_db_type,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,filter_db_type,operator,value,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
         columnRoles:ui_columns_roles(id,role_id),
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,
              section:ui_sections(id,sectionRoles:ui_sections_roles(id,role_id),title,description,webhook_url,config,sort,is_close,is_admin_only)
            ),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,is_deleted)
          )
        ),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      views:ui_pages_views(id,is_custom,default_section_id,record_default_section_id,
        pageViewRoles:ui_pages_views_roles(id,role_id),
        defaultSection:default_section_id(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id))),pages_id,view:views_id(*),
        columns:ui_pages_views_columns(*,column:columns_id(id)),
        conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        tabs:ui_pages_views_tabs(id,page_view_id,created_at,is_child,is_hidden,tab:ui_tabs(id,
          tab_page_id(id,title,path,table_name),
          default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),
          addPageFilters:add_page_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
          tabFilters:tab_filter_id(id,
              columnRules:ui_column_condition(id,group_condition_id,
                pageColumn:ui_page_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)
            ),
          recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count))),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(id,ui_views_id, section_id, section:section_id(id,title,type,config,sort), actionRoles:ui_pages_actions_roles(id, role_id), ui_action_id(id, name, type, icon, requires_confirmation,confirmation_color, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),
      algolia_search_table_id`
    )
    .eq("views.view.is_deleted", false)
    .eq("path", path.trim())
    .eq("user_type", userType)
    .eq("is_deleted", false);
  if (!skipWorkspaceIdCheck && workspaceIds?.length) {
    supabaseSelectRef = supabaseSelectRef.in("workspace_id", workspaceIds);
  }

  const { data, error } = await supabaseSelectRef;
  let finalPage = data?.[0];
  if ((data?.length || 0) > 1 && pickDefault) {
    finalPage = data?.find((page) => !!page.is_default_table_page);
  }

  const finalPageData = finalPage ? (mapApiPageToPage(finalPage as RecordItem as ApiPage) as Page) : null;
  // if it is a cached page and data is present, cache the page
  if (isCachedPage && finalPageData) {
    await updateCacheValue(cachedPageKey, finalPageData, supabaseClient);
  }

  return {
    data: finalPageData,
    error: (error as PostgrestError) || (null as any),
    count: null as any,
    status: 200,
    statusText: ""
  };
};

export const getPageViewSections = async ({
  supabaseClient,
  pageId,
  pageSlug,
  userType,
  source,
  workspaceIds
}: {
  supabaseClient: SupabaseClient;
  pageId?: string;
  pageSlug?: string;
  userType?: USER_TYPE;
  source?: string;
  workspaceIds?: string[];
}): Promise<TableViewConfigsResponse> => {
  if ((!pageId && !pageSlug) || (!!pageSlug && !userType) || !workspaceIds?.length) {
    // @ts-ignore
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,views:ui_pages_views(id,is_custom,default_section_id,record_default_section_id,pages_id, pageViewRoles:ui_pages_views_roles(id,role_id),view:views_id(*),
        sections:ui_sections!public_ui_column_sections_page_view_id_fkey(id,
          sectionRoles:ui_sections_roles(id,role_id),
          title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
          pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id)),
          conditionalViewRules:conditional_view_rule_id(id,rule_config,
            columnRules:ui_column_condition(id,group_condition_id,
              colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),
              pageColumn:ui_page_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          ),
        filter:ui_filters_id(id,
          columnRules:ui_column_condition(id,group_condition_id,colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,is_user_visible,option_value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      ))
   `
    )
    .eq("views.view.is_deleted", false)
    .is("views.sections.for_user_id", null)
    .eq("is_deleted", false)
    .in("workspace_id", workspaceIds);

  if (pageId) {
    supabaseSelectRef = supabaseSelectRef.eq("id", pageId);
  } else if (pageSlug && userType) {
    supabaseSelectRef = supabaseSelectRef.eq("path", pageSlug).eq("user_type", userType);
  }
  const { data, error } = await supabaseSelectRef;

  return {
    data: data?.[0]?.views ? mapApiPageViewsToPageViews(data[0].views as RecordItem as ApiPageViews[]) : (null as any),
    error: (error as PostgrestError) || (null as any),
    count: null as any,
    status: 200,
    statusText: ""
  };
};

export const getPageUserViewSections = async ({
  supabaseClient,
  userId,
  pageId,
  workspaceIds
}: {
  supabaseClient: SupabaseClient;
  userId?: string;
  pageId?: string;
  workspaceIds?: string[];
}) => {
  if (!userId || !pageId || !workspaceIds?.length) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `id,views:ui_pages_views(id,is_custom,default_section_id,record_default_section_id,pages_id, pageViewRoles:ui_pages_views_roles(id,role_id),view:views_id(*),
      sections:ui_sections!public_ui_column_sections_page_view_id_fkey(id,sectionRoles:ui_sections_roles(id,role_id),title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
      pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id)),
      filter:ui_filters_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*,column:columns_id(id)),field,operator,value,is_user_visible,filter_db_type,option_value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      ))
   `
    )
    .eq("views.view.is_deleted", false)
    .eq("views.sections.for_user_id", userId)
    .eq("id", pageId)
    .eq("is_deleted", false)
    .in("workspace_id", workspaceIds);

  return {
    data: data?.[0]?.views ? mapApiPageViewsToPageViews(data[0]?.views as unknown as ApiPageViews[]) : null,
    error
  };
};

const constructPageLiteSelect = ({
  baseTableColumnNames,
  additionalColumns,
  additionalDataProps
}: {
  baseTableColumnNames?: string[];
  additionalColumns?: TableColumnType[];
  additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
}) => {
  let select =
    "id,path,title,table_name,is_default_table_page,user_type,head_title,page_config,menu_id,algolia_search_table_id,has_error,is_admin_page,workspace_id,parent_id";
  if (baseTableColumnNames?.length) {
    select += "," + baseTableColumnNames.join(",");
  }

  if (additionalColumns?.length) {
    select +=
      "," +
      constructSupabaseSelect({
        columns: additionalColumns,
        baseTableName: "pages"
      });
  }

  if (additionalDataProps?.includes("views")) {
    select += `,views:ui_pages_views(id,default_section_id,record_default_section_id,is_custom,view:views_id(*), pageViewRoles:ui_pages_views_roles(id,role_id),columns:ui_pages_views_columns(*),
        conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)), 
        tabs:ui_pages_views_tabs(
        id,created_at,is_child,is_hidden, page_view_id,tab:ui_tabs(
          id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)))`;
  }

  if (additionalDataProps?.includes("views_lite")) {
    select +=
      ",views:ui_pages_views(id,is_custom,pageViewRoles:ui_pages_views_roles(id,role_id),view:views_id(*),columns:ui_pages_views_columns(*,column:columns_id(id)), conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)), tabs:ui_pages_views_tabs(id,is_child,is_hidden,created_at,tab:ui_tabs(id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,add_page_id(id,path,title,table_name),recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)))";
  }

  if (additionalDataProps?.includes("columns")) {
    select += `,columns:ui_pages_columns(*,
      lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,filter_db_type,lookup_column_name_field,filter_group,include_null)),
      columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
      column:columns_id(*,
        columnRoles:ui_columns_roles(id,role_id),
        ui_pages_views_columns(*,columnSections:ui_pages_views_column_sections(id,section:ui_sections(id,sectionRoles:ui_sections_roles(id,role_id),title,description,webhook_url,config,sort,is_close,is_admin_only)),view:pages_views_id(id,pages_id,views_id(id,view_type,is_deleted))),
      conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
    ))`;
  }

  if (additionalDataProps?.includes("columns_lite")) {
    select += `,
    columns:ui_pages_columns(*,
      lookupFilters:lookup_filter_id(id,
        columnRules:ui_column_condition(id,group_condition_id,
          pageColumn:ui_page_column_id(*, 
            column:columns_id(id)
          ),
          field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
        )
      ),
      column:columns_id(*,
        ui_pages_views_columns(*,
          view:pages_views_id(id,pages_id,
            views_id(id,view_type,is_deleted)
          )
        )
      )
    )`;
  }

  if (additionalDataProps?.includes("actions")) {
    select += `,ui_pages_actions(id, ui_views_id,section_id,section:section_id(id,title,type,config,sort),actionRoles:ui_pages_actions_roles(id, role_id), ui_action_id(id, name, type, icon, requires_confirmation, confirmation_color, has_extended_menu),sort_order, action_properties,page_id,visibility, bulk_sort_order, wait_for_success, has_divider, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)))`;
  }

  if (additionalDataProps?.includes("pageFilters")) {
    select += `,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,group_condition_id,colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))`;
  }
  if (additionalDataProps?.includes("column_rules")) {
    select += `,conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
    ui_pages_actions(id,ui_views_id,section_id,section:section_id(id,title,type,config,sort),actionRoles:ui_pages_actions_roles(id, role_id), ui_action_id(id, name, type, icon, requires_confirmation, confirmation_color, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)))`;
  }
  return select;
};

export const getPageByPathLite = async (
  supabaseClient: SupabaseClient,
  {
    baseTableColumnNames,
    additionalColumns,
    path,
    userType,
    additionalDataProps = [],
    source,
    workspaceIds,
    skipWorkspaceIdCheck,
    pickDefault
  }: {
    baseTableColumnNames?: string[];
    additionalColumns?: TableColumnType[];
    path: string;
    userType: USER_TYPE;
    additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
    source?: string; // Used to debug the source of the request
    workspaceIds?: string[];
    skipWorkspaceIdCheck?: boolean;
    pickDefault?: boolean;
  }
): Promise<BAPageResponse> => {
  if (!path || !userType || path === "/" || (!skipWorkspaceIdCheck && !workspaceIds?.length)) {
    return { data: null, error: null };
  }

  const isCachedPage = CACHED_PAGES_SLUGS.includes(path) && !baseTableColumnNames?.length && !additionalColumns?.length;
  const cachedPageKey = `${SA_CACHE_KEYS.PAGE_DATA_BY_PATH_LITE}${path}-${userType}-${!!pickDefault}`;

  // check if page is cachedPage and fetch from cache and return if present
  if (isCachedPage) {
    const cachedValue = await getSACachedValue(cachedPageKey, supabaseClient);
    if (cachedValue?.data?.value) {
      return {
        data: cachedValue?.data?.value as Page,
        error: null,
        count: null as any,
        status: 200,
        statusText: ""
      };
    }
  }

  const select = constructPageLiteSelect({ baseTableColumnNames, additionalColumns, additionalDataProps });
  let supabaseQueryRef = supabaseClient.from("pages").select(select).eq("path", path.trim()).eq("user_type", userType);

  if (additionalDataProps.includes("views") || additionalDataProps.includes("views_lite")) {
    supabaseQueryRef = supabaseQueryRef.eq("views.view.is_deleted", false);
  }
  if (!skipWorkspaceIdCheck && workspaceIds?.length) {
    supabaseQueryRef = supabaseQueryRef.in("workspace_id", workspaceIds);
  }
  const { data, error } = await supabaseQueryRef.eq("is_deleted", false);
  let finalPage = data?.[0];
  if ((data?.length || 0) > 1 && pickDefault) {
    finalPage = data?.find((page) => !!(page as RecordItem).is_default_table_page);
  }

  const finalPageData = finalPage ? (mapApiPageToPage(finalPage as RecordItem as ApiPage) as Page) : null;
  // if it is a cached page and data is present, cache the page
  if (isCachedPage && finalPageData) {
    await updateCacheValue(cachedPageKey, finalPageData, supabaseClient);
  }

  return {
    data: finalPageData,
    error
  };
};

export const getPageByIDLite = async (
  supabaseClient: SupabaseClient,
  {
    baseTableColumnNames,
    additionalColumns,
    id,
    additionalDataProps = [],
    workspaceIds,
    skipWorkspaceIdCheck
  }: {
    baseTableColumnNames?: string[];
    additionalColumns?: TableColumnType[];
    id?: string;
    additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
    source?: string; // Used to debug the source of the request
    workspaceIds?: string[];
    skipWorkspaceIdCheck?: boolean;
  }
): Promise<BAPageResponse> => {
  if (!id || (!skipWorkspaceIdCheck && !workspaceIds?.length)) {
    return { data: undefined, error: null };
  }

  const isCachedPage =
    CACHED_PAGES_IDS.includes(`${id}`) && !baseTableColumnNames?.length && !additionalColumns?.length;
  const cachedPageKey = `${SA_CACHE_KEYS.PAGE_DATA_BY_ID_LITE}${id}`;

  // check if page is cachedPage and fetch from cache and return if present
  if (isCachedPage) {
    const cachedValue = await getSACachedValue(cachedPageKey, supabaseClient);
    if (cachedValue?.data?.value) {
      return {
        data: cachedValue?.data?.value as Page,
        error: null,
        count: null as any,
        status: 200,
        statusText: ""
      };
    }
  }

  const select = constructPageLiteSelect({ baseTableColumnNames, additionalColumns, additionalDataProps });
  let supabaseQueryRef = supabaseClient.from("pages").select(select).eq("id", id);

  if (additionalDataProps.includes("views") || additionalDataProps.includes("views_lite")) {
    supabaseQueryRef = supabaseQueryRef.eq("views.view.is_deleted", false);
  }
  if (workspaceIds?.length) {
    supabaseQueryRef = supabaseQueryRef.in("workspace_id", workspaceIds);
  }
  const { data, error } = await supabaseQueryRef.eq("is_deleted", false);

  const finalPageData = data?.[0] ? (mapApiPageToPage(data[0] as RecordItem as ApiPage) as Page) : undefined;
  // if it is a cached page and data is present, cache the page
  if (isCachedPage && finalPageData) {
    await updateCacheValue(cachedPageKey, finalPageData, supabaseClient);
  }

  return { data: finalPageData, error };
};

export const getPagesWithFiltersLinkedToSearchTableId = async (
  searchTableId: string,
  supabaseClient: SupabaseClient
): Promise<BAPagesResponse> => {
  const select = `id,path,title,is_admin_page,table_name,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))`;
  const { data, error } = await supabaseClient
    .from("pages")
    .select(select)
    .eq("algolia_search_table_id", searchTableId);

  if (!data?.length || error) {
    return { data: null, error };
  }
  // @ts-ignore
  return { data: data.map((page) => mapApiPageToPage(page)), error };
};

// this function is used by the hook which is only used in page adminViewConfig
export const getPageDataByIdWithChildPages = async (
  supabaseClient: SupabaseClient,
  id: string,
  workspaceIds?: string[]
): Promise<PagesResponse> => {
  if (!id || !workspaceIds?.length) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `id,is_deleted,workspace_id,is_default_table_page,page_config,menu_id,created_in_path,is_default_table_page,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,path,redirect_to,skip_record_sidebar_view,sort,static_content,table_name,title,user_type,
      pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,group_condition_id,colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,is_user_visible,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,filter_db_type,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,filter_db_type,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
          columnRoles:ui_columns_roles(id,role_id),
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,
                section:ui_sections(id,title,description,webhook_url,config,sort,is_close)
            ),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,is_deleted)
          )
        ),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      parentPage:parent_id(id,path,title),
      views:ui_pages_views(id,pageViewRoles:ui_pages_views_roles(id,role_id),
        conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        default_section_id,record_default_section_id,is_custom,
        sections:ui_sections!public_ui_column_sections_page_view_id_fkey(id,
          sectionRoles:ui_sections_roles(id,role_id),
          title,description,webhook_url,config,sort,is_close,is_admin_only,
          filter:ui_filters_id(id,
            columnRules:ui_column_condition(id,group_condition_id,
              colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),
              column_id,field,operator,is_user_visible,
              option_value,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)
            ),
          conditionalViewRules:conditional_view_rule_id(id,rule_config,
            columnRules:ui_column_condition(id,group_condition_id,
              pageColumn:ui_page_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          )
          ),
          view:views_id(*),
          columns:ui_pages_views_columns(*,
          column:columns_id(id)
        ),
        tabs:ui_pages_views_tabs(id,created_at,is_child,is_hidden,
          tab:ui_tabs(id,
            tab_page_id(id,title,path,table_name),
            default_view,sidebar_default_view,title,icon,filter_relations,
            add_page_id(id,path,title,table_name),
            addPageFilters:add_page_filter_id(id,
              columnRules:ui_column_condition(id,group_condition_id,
                pageColumn:ui_page_column_id(*, column:columns_id(id)),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)
            ),
            tabFilters:tab_filter_id(id,
              columnRules:ui_column_condition(id,group_condition_id,
                pageColumn:ui_page_column_id(*, column:columns_id(id)),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)
            ),
            recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count))),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(id,ui_views_id,section_id,section:section_id(id,title,type,config,sort),actionRoles:ui_pages_actions_roles(id, role_id), ui_action_id(id, name, type, icon, requires_confirmation, confirmation_color, has_extended_menu),sort_order, action_properties,page_id,visibility, bulk_sort_order, wait_for_success, has_divider, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),
      algolia_search_table_id`
    )
    .or(`id.eq.${id},parent_id.eq.${id}`)
    .is("views.sections.for_user_id", null)
    .eq("is_deleted", false);
  // @ts-ignore
  return { data, error };
};

export const getAllSiblingPages = async (supabaseClient: SupabaseClient, id?: string): Promise<PagesResponse> => {
  if (!id) {
    return { data: [], error: null };
  }

  const { data, error } = await supabaseClient
    .from("pages")
    .select("*, parent_id(id, title)")
    .eq("parent_id", id)
    .eq("is_deleted", false);

  return { data, error };
};

export const getPageDataById = async (
  supabaseClient: SupabaseClient,
  id: string,
  workspaceIds?: string[],
  skipWorkspaceIdCheck?: boolean
): Promise<RecordItem> => {
  if (!id || (!skipWorkspaceIdCheck && !workspaceIds?.length)) {
    return { data: null, error: null };
  }

  const isCachedPage = CACHED_PAGES_IDS.includes(`${id}`);
  const cachedPageKey = `${SA_CACHE_KEYS.PAGE_DATA_BY_ID}${id}`;

  // check if page is cachedPage and fetch from cache and return if present
  if (isCachedPage) {
    const cachedValue = await getSACachedValue(cachedPageKey, supabaseClient);
    if (cachedValue?.data?.value) {
      return {
        data: cachedValue?.data?.value as Page,
        error: null,
        count: null as any,
        status: 200,
        statusText: ""
      };
    }
  }

  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,is_default_table_page,workspace_id,is_deleted,page_config,menu_id,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,parentPage:parent_id(id,path),path,redirect_to,skip_record_sidebar_view,sort,static_content,table_name,title,user_type,
      pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,group_condition_id,colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,is_user_visible,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
          columnRoles:ui_columns_roles(id,role_id),
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,section:ui_sections(id,sectionRoles:ui_sections_roles(id,role_id),title,description,webhook_url,config,sort,is_close,is_admin_only)),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,is_deleted))),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      views:ui_pages_views(id,
        pageViewRoles:ui_pages_views_roles(id,role_id),
        conditionalFormatting:ui_column_rules(id, rule_config, 
          columnRules:ui_column_condition(id,
            group_condition_id,
            ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
          )
        ),
        default_section_id,record_default_section_id,defaultSection:default_section_id(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id))),is_custom,view:views_id(*),columns:ui_pages_views_columns(*),
        tabs:ui_pages_views_tabs(id,is_child,is_hidden,created_at,
          tab:ui_tabs(id,
          tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),
          addPageFilters:add_page_filter_id(id,
            columnRules:ui_column_condition(id,group_condition_id,
              pageColumn:ui_page_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          ),
          tabFilters:tab_filter_id(id,
              columnRules:ui_column_condition(id,group_condition_id,
                pageColumn:ui_page_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)
            ),
          recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)
          )
        ),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(
        id, ui_views_id, section_id,section:section_id(id,title,type,config,sort), actionRoles:ui_pages_actions_roles(id, role_id),
        ui_action_id(id, name, type, icon, requires_confirmation, confirmation_color, has_extended_menu),
        sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider,
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      ),
      algolia_search_table_id`
    )
    .eq("views.view.is_deleted", false)
    .eq("id", id);
  if (!skipWorkspaceIdCheck && workspaceIds?.length) {
    supabaseSelectRef = supabaseSelectRef.in("workspace_id", workspaceIds);
  }

  const { data, error } = await supabaseSelectRef.single();
  if (!error && data) {
    const finalPageData = mapApiPageToPage(data as RecordItem as ApiPage) as Page;
    // if it is a cached page and data is present, cache the page
    if (isCachedPage && finalPageData) {
      await updateCacheValue(cachedPageKey, finalPageData, supabaseClient);
    }
    return { data: finalPageData, error };
  }
  return { data, error };
};

export const getAllActions = async (
  supabaseClient: SupabaseClient,
  workspaceIds?: string[],
  skipWorkspaceIdCheck?: boolean
): Promise<ActionsResponse> => {
  if (!workspaceIds?.length) {
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient.from("ui_actions").select();
  if (!skipWorkspaceIdCheck && workspaceIds?.length) {
    supabaseSelectRef = supabaseSelectRef.in("workspace_id", workspaceIds);
  }
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const getActionByName = async (
  supabaseClient: SupabaseClient,
  name: string,
  workspaceIds?: string[]
): Promise<ActionResponse> => {
  if (!workspaceIds?.length || !name) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_actions")
    .select()
    .eq("name", name)
    .in("workspace_id", workspaceIds)
    .maybeSingle();

  return { data, error };
};

export const getAllMenus = async (supabaseClient: SupabaseClient, workspaceIds?: string[]): Promise<MenusResponse> => {
  if (!workspaceIds?.length) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_menus")
    .select("id,name,user_type,is_deleted")
    .eq("is_deleted", false)
    .in("workspace_id", workspaceIds);
  return { data, error };
};

export const getAllColumnTemplates = async (
  supabaseClient: SupabaseClient,
  workspaceIds?: string[]
): Promise<ColumnTemplateResponse> => {
  if (!workspaceIds?.length) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_page_column_templates")
    .select()
    .eq("is_deleted", false)
    .in("workspace_id", workspaceIds);
  return { data, error };
};

export const getAllFilterTemplates = async (
  supabaseClient: SupabaseClient,
  workspaceIds?: string[]
): Promise<FilterTemplateResponse> => {
  if (!workspaceIds?.length) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient.from("ui_page_filter_templates").select().eq("is_deleted", false);
  return { data, error };
};

export const getPageSearchTable = async (
  supabaseClient: SupabaseClient,
  id: string,
  workspaceId?: string
): Promise<BAPageResponse> => {
  if (!id || !workspaceId) {
    return { data: undefined, error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `id,
    algoliaSearchTablePage:algolia_search_table_id(*, 
      columns:algolia_search_table_columns(*, 
        column:ui_pages_columns_id(id,
          lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
          page_id(id,path,title),
          columns_id(*)
        )
      )
    )`
    )
    .eq("workspace_id", workspaceId);

  return { data: data?.[0] ? mapApiPageToPage(data[0] as RecordItem as ApiPage) : undefined, error };
};

export const getSearchTableById = async (
  supabaseClient: SupabaseClient,
  id?: string,
  workspaceId?: string,
  isAdminPage?: boolean
): Promise<AlgoliaSearchTableResponse> => {
  if (!id || (!isAdminPage && !workspaceId)) return { data: null, error: null };
  let supabaseRef = supabaseClient
    .from("algolia_search_tables")
    .select(
      `*,
        linkedPage:linked_page_id(id,path,title,table_name), 
        algoliaColumns:algolia_search_table_columns(*, 
          pageColumn:ui_pages_columns_id(id,page_id(id,path,title),
          column:columns_id(id,header,name,type,is_lookup,lookup_path)))`
    )
    .eq("id", id)
    .eq("is_deleted", false);
  if (!isAdminPage) {
    supabaseRef = supabaseRef.eq("workspace_id", workspaceId);
  }

  const { data, error } = await supabaseRef;
  //@ts-ignore
  return { data: data?.[0] ? mapApiAlgoliaSearchTableToAlgoliaSearchTable(data[0]) : null, error };
};

export const getSearchTableByIdWithColumns = async (
  supabaseClient: SupabaseClient,
  id?: string
): Promise<AlgoliaSearchTableResponse> => {
  if (!id) return { data: null, error: null };

  const { data, error } = await supabaseClient
    .from("algolia_search_tables")
    .select(
      `*,
      linkedPage:linked_page_id(id,path,title), 
      algoliaColumns:algolia_search_table_columns(*, 
        pageColumn:ui_pages_columns_id(id,
          lookupFilters:lookup_filter_id(id,
            columnRules:ui_column_condition(id,group_condition_id,
              pageColumn:ui_page_column_id(*, 
                column:columns_id(id)
              ),
              field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          ),
          page_id(id,path,title),
          column:columns_id(*)
        )
      )`
    )
    .eq("id", id)
    .eq("is_deleted", false);

  //@ts-ignore
  return { data: data?.[0] ? mapApiAlgoliaSearchTableToAlgoliaSearchTable(data[0]) : null, error };
};

export const getSearchTables = async (
  supabaseClient: SupabaseClient,
  workspaceId?: string
): Promise<AlgoliaSearchTablesResponse> => {
  if (!workspaceId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("algolia_search_tables")
    .select(
      `*,
      linkedPage:linked_page_id(id,path,title), 
      algoliaColumns:algolia_search_table_columns(*, 
        pageColumn:ui_pages_columns_id(id,
          lookupFilters:lookup_filter_id(id,
            columnRules:ui_column_condition(id,group_condition_id,
              pageColumn:ui_page_column_id(*, 
                column:columns_id(id)
              ),
              field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          ),
          page_id(id,path,title),
          column:columns_id(*)
        )
      )`
    )
    .eq("is_deleted", false);

  return {
    //@ts-ignore
    data: data?.length
      ? data.map((searchTable: ApiAlgoliaSearchTable) => mapApiAlgoliaSearchTableToAlgoliaSearchTable(searchTable))
      : null,
    error
  };
};

export const getTiles = async ({
  supabaseClient,
  publicId,
  slug,
  type,
  disablePageRender,
  onlyFetchMetadata
}: {
  supabaseClient: SupabaseClient;
  publicId?: string;
  slug?: string;
  type?: string;
  disablePageRender?: TILE_DISABLE_PAGE_RENDER;
  onlyFetchMetadata?: boolean;
}): Promise<TilesResponse> => {
  let select =
    "public_id,slug,is_deleted,document_title,meta_title,no_index,meta_description,share_title,share_description,canonical_url_override,canonical_url,og_image_subtitle,og_image_title,og_file_id(id, path)";

  if (!onlyFetchMetadata) {
    select +=
      ",parent_id,show_confetti_on_load,additional_config,language,author_contact_id,deal_id,status_id,name,type,intro_message,intro_display,tiles_components:tiles_components(*,order,component_id:component_id (*))";
  }
  // Don't include id here as this is public data
  let supabaseSelectRef = supabaseClient.from("tiles").select(select);

  if (publicId) {
    supabaseSelectRef = supabaseSelectRef.eq("public_id", publicId);
  } else if (slug) {
    supabaseSelectRef = supabaseSelectRef.eq("slug", slug.startsWith("/") ? slug : `/${slug}`);
  }

  if (type) {
    supabaseSelectRef = supabaseSelectRef.eq("type", type);
  }

  if (disablePageRender === TILE_DISABLE_PAGE_RENDER.TYPE && !type) {
    supabaseSelectRef = supabaseSelectRef.or(`disable_page_render.is.null,disable_page_render.neq.type`);
  }

  if (!onlyFetchMetadata) {
    supabaseSelectRef = supabaseSelectRef.eq("tiles_components.is_deleted", false);
  }

  const { data, error } = await supabaseSelectRef
    .eq("is_published", true)
    .eq("is_deleted", false)
    .eq("workspace_id", 1);
  // @ts-ignore
  return { data, error };
};

export const getTileByPublicId = async (
  supabaseClient: SupabaseClient,
  id?: string,
  slug?: string,
  isAdmin?: boolean
): Promise<TilesResponse> => {
  // Don't include id here as this is public data by default only add if requested
  let supabaseSelectRef = supabaseClient.from("tiles").select(
    `
    ${isAdmin ? `id,` : ``}
    public_id,
    is_deleted,
    parent_id,
    author_contact_id,
    deal_id,
    slug,
    status_id,
    name,
    type,
    document_title,
    intro_message,
    intro_display,
    meta_title,
    no_index,
    meta_description,
    share_title,
    share_description,
    canonical_url_override,
    canonical_url,
    og_image_subtitle,
    og_image_title,
    og_file_id(id, path),
    show_confetti_on_load,
    additional_config,
    language,
    tiles_components:tiles_components (
      *,
      order,
      component_id:component_id (
        *
      )
    )
  `
  );

  if (id) {
    supabaseSelectRef = supabaseSelectRef.eq("public_id", id);
  } else if (slug) {
    supabaseSelectRef = supabaseSelectRef.eq("slug", slug.startsWith("/") ? slug : `/${slug}`);
  }
  if (!isAdmin) {
    supabaseSelectRef = supabaseSelectRef.eq("is_published", true);
  }
  const { data, error } = await supabaseSelectRef.eq("tiles_components.is_deleted", false);

  return { data: data as RecordItem[] as Tiles[], error };
};

export const getTileById = async (supabaseClient: SupabaseClient, id: string): Promise<TilesResponse> => {
  // this fetches using ID
  const { data, error } = await supabaseClient
    .from("tiles")
    .select(
      `
      id,
      public_id,
      is_deleted,
      parent_id,
      author_contact_id,
      deal_id,
      slug,
      status_id,
      name,
      type,
      document_title,
      intro_message,
      intro_display,
      meta_title,
      no_index,
      meta_description,
      share_title,
      share_description,
      canonical_url_override,
      canonical_url,
      og_image_subtitle,
      og_image_title,
      og_file_id(id, path),
      show_confetti_on_load,
      additional_config,
      language,
      tiles_components:tiles_components (
        *,
        order,
        component_id:component_id (
          *
        )
      )
    `
    )
    .eq("id", id)
    .eq("tiles_components.is_deleted", false);
  return { data: data as RecordItem[] as Tiles[], error };
};

export const getEditorComponents = async (supabaseClient: SupabaseClient): Promise<ComponentsResponse> => {
  const { data, error } = await supabaseClient.from("components").select("*").eq("use_in_editor", true);
  return { data, error };
};

export const updateSortNullValues = async ({
  tableName,
  column,
  filters,
  isJoinTable
}: {
  tableName: string;
  column: TableColumnType;
  filters?: { filters: TableFilterType[] };
  isJoinTable?: boolean;
}) => {
  const res = await fetch("/api/tabledata/update_sort_null_values", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ tableName, column, filters, isJoinTable })
  }).then((res) => res.json());

  return res;
};

export const getTileDataByPublicId = async (tileId: string): Promise<TilesResponse> => {
  if (!tileId) return Promise.resolve({ data: [], error: null });
  const result = await fetch(`/api/tiles/get_tile_by_public_id?tileId=${tileId}`).then((res) => res.json());
  return result;
};

export const getTileDataBySlug = async (tileSlug: string): Promise<TilesResponse> => {
  if (!tileSlug) return Promise.resolve({ data: [], error: null });
  const result = await fetch(`/api/tiles/get_tile_by_public_id?tileSlug=${tileSlug}`).then((res) => res.json());
  return result;
};

export const getAllComponents = async (
  supabaseClient: SupabaseClient,
  pagination?: PaginationState,
  filter?: { componentId?: string; show_explorer?: boolean }
): Promise<TileComponentsResponse> => {
  let supabaseRef = supabaseClient
    .from("tiles_components")
    .select("*,component_id(*)", { count: "estimated", head: false })
    .order("id", { ascending: false });

  if (pagination?.limit) {
    supabaseRef = supabaseRef.limit(pagination.limit);
  }
  if (filter?.componentId) {
    supabaseRef = supabaseRef.eq("component_id.id", filter.componentId);
  }
  if (filter?.show_explorer) {
    supabaseRef = supabaseRef.eq("show_explorer", true);
  }
  if (!pagination?.limit && pagination?.currentPage && pagination?.pageSize) {
    const pageIndex = pagination.currentPage - 1;
    const pageSize = pagination.pageSize;
    supabaseRef = supabaseRef.range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1);
  }
  const { data, error, count } = await supabaseRef;
  return { data, error, count: count || undefined };
};

export const getTileComponentByComponentId = async (
  supabaseClient: SupabaseClient,
  componentId: string
): Promise<TileComponentsResponse> => {
  const { data, error } = await supabaseClient
    .from("tiles_components")
    .select("*,component_id(*)")
    .eq("component_id", componentId);

  return { data, error };
};

export const getColumnTemplateById = async (
  supabaseClient: SupabaseClient,
  id: string,
  workspaceId?: string
): Promise<ColumnTemplateResponse> => {
  if (!workspaceId || !id) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_page_column_templates")
    .select("id, name, cell_type, config")
    .eq("id", id)
    .eq("workspace_id", workspaceId);
  return { data, error };
};

export const getTileReviewsData = async (supabaseClient: SupabaseClient): Promise<ReviewsResponse> => {
  const supabaseSelectRef = supabaseClient
    .from("reviews")
    .select("*")
    .eq("website_display", true)
    .eq("is_deleted", false)
    .order("sort", { ascending: false });
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

// Checks by subdomain if domain is solve, else is soldave
export const getIsStaffSubdomainForHostAndWorkspaceId = async (
  host: string
): Promise<{ isStaffSubdomain: boolean; workspaceId?: string; isRenovateDomain?: boolean }> => {
  if (!host) {
    return { isStaffSubdomain: false };
  }
  const hostParts = host.split(".");
  let subDomain = "";
  let domain = "";
  if (hostParts.length === 3) {
    // Has subdomain
    subDomain = hostParts[0];
    domain = hostParts[1] + "." + hostParts[2];
  } else if (hostParts.length === 2) {
    // No subdomain
    domain = hostParts[0] + "." + hostParts[1];
  }

  if (IS_VERVEL_PREVIEW) {
    return {
      isStaffSubdomain:
        subDomain.startsWith("soldave") ||
        domain.startsWith("soldave") ||
        subDomain === "soldave" ||
        subDomain === "staff" ||
        subDomain.startsWith("staff")
    };
  }
  if (IS_LOCAL_DEV) {
    return {
      isStaffSubdomain:
        subDomain.startsWith("soldave") ||
        domain.startsWith("soldave") ||
        subDomain === "staff" ||
        subDomain.startsWith("staff"),
      workspaceId: "1"
    };
  }

  return {
    isStaffSubdomain:
      subDomain.startsWith("soldave") ||
      domain.startsWith("soldave") ||
      subDomain === "soldave" ||
      subDomain === "staff" ||
      subDomain.startsWith("staff"),
    workspaceId: "1" // ##HARDCODED to org id 1 as currently there is no multi  org support
  };
};

export const getMenuById = async (
  supabaseClient: SupabaseClient,
  id: string,
  workspaceIds?: string[]
): Promise<MenusResponse> => {
  if (!workspaceIds) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_menus")
    .select(
      `*, 
      ui_menu_items(*,
        menuItemRoles:ui_menu_items_roles(id,role_id), 
        ui_page_id(id, path, title, table_name, default_view, mobile_default_view, metabase_dashboard_id, page_type, is_admin_page), 
        ui_menu_actions(id,  menuActionRoles:ui_menu_actions_roles(id,role_id), action_properties, wait_for_success, has_divider, sort, ui_action_id(name, icon,type))
      ), 
      pages:pages!pages_menu_id_fkey(id,table_name, path,views:ui_pages_views(id,pageViewRoles:ui_pages_views_roles(id,role_id),default_section_id,record_default_section_id,is_custom,view:views_id(*), conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)), tabs:ui_pages_views_tabs(id,tab:ui_tab_id(id,tab_page_id(path),ui_menu_item_id))))`
    )
    .eq("id", id)
    .eq("is_deleted", false)
    .eq("ui_menu_items.is_deleted", false)
    .in("ui_menu_items.workspace_id", workspaceIds)
    .in("workspace_id", workspaceIds)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true });
  return { data, error };
};

export const getMenusByPage = async (
  supabaseClient: SupabaseClient,
  pageId: string | null,
  userType?: string,
  workspaceId?: string
): Promise<MenusResponse> => {
  if (!workspaceId) {
    return {
      data: null,
      error: {
        name: "Invalid workspace id",
        message: "Invalid workspace id",
        details: "Workspace id is missing for the user",
        code: "WORKSPACE_INVALID",
        hint: "Please check user details or contact admin"
      }
    };
  }
  let supabaseClientQuery = supabaseClient
    .from("ui_menus")
    .select(
      `*, 
    ui_menu_items!inner(*,
     menuItemRoles:ui_menu_items_roles(id,role_id),
     ui_page_id(id, path, title, default_view, mobile_default_view, table_name, metabase_dashboard_id, page_type, is_admin_page), 
      ui_menu_actions(id,  menuActionRoles:ui_menu_actions_roles(id,role_id), action_properties, wait_for_success, has_divider, sort,ui_action_id(name, icon, type))
    )
    
     ${pageId ? ",pages:pages!pages_menu_id_fkey!inner(id,table_name, path,views:ui_pages_views(id,pageViewRoles:ui_pages_views_roles(id,role_id),default_section_id,record_default_section_id,is_custom,view:views_id(*), conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)), tabs:ui_pages_views_tabs(id,tab:ui_tab_id(id,add_page_id,default_add_expanded,tab_page_id(path),ui_menu_item_id))))" : ""}
     )
   )`
    )
    .eq("ui_menu_items.workspace_id", workspaceId)
    .eq("workspace_id", workspaceId);

  if (!pageId) {
    supabaseClientQuery = supabaseClientQuery.is("is_global", true);
  } else if (pageId) {
    supabaseClientQuery = supabaseClientQuery.in("pages.id", [pageId]);
  }

  const { data, error } = await supabaseClientQuery
    .eq("is_deleted", false)
    .in("user_type", [userType, "All"])
    .eq("ui_menu_items.is_deleted", false)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true });

  // @ts-ignore
  return { data, error };
};

export const getGlobalMenu = async (
  supabaseClient: SupabaseClient,
  userType: string,
  userWorkspaceId: string
): Promise<MenuResponse> => {
  if (!userWorkspaceId) {
    return {
      data: null,
      error: {
        name: "Invalid workspace id",
        message: "Invalid workspace id",
        details: "Workspace id is missing for the user",
        code: "WORKSPACE_INVALID",
        hint: "Please check user details or contact admin"
      }
    };
  }
  const response = await supabaseClient
    .from("ui_menus")
    .select(
      `is_global, workspace_id,
        ui_menu_items!inner(*, 
          menuItemRoles:ui_menu_items_roles(id,role_id),
          ui_page_id(id, path, title, default_view, mobile_default_view, table_name, metabase_dashboard_id, page_type, is_admin_page), 
          ui_menu_actions(id,menuActionRoles:ui_menu_actions_roles(id,role_id), action_properties, wait_for_success, has_divider, sort, ui_action_id(name, icon, type)))`
    )
    .eq("is_deleted", false)
    .eq("is_global", true)
    .in("user_type", [userType, "All"])
    .eq("ui_menu_items.is_deleted", false)
    .eq("ui_menu_items.workspace_id", userWorkspaceId)
    .eq("workspace_id", userWorkspaceId)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true })
    .maybeSingle();

  // @ts-ignore
  return response;
};

export const getGlobalMenuLite = async (
  supabaseClient: SupabaseClient,
  {
    userType,
    baseTableColumnNames,
    additionalColumns,
    workspaceId
  }: {
    userType: string;
    isAdminMenu?: boolean;
    baseTableColumnNames?: string[];
    additionalColumns?: TableColumnType[];
    workspaceId?: string;
  }
): Promise<MenuResponse> => {
  let select = "id,is_global,is_deleted,user_type";
  if (baseTableColumnNames?.length) {
    select += "," + baseTableColumnNames.join(",");
  }

  if (additionalColumns?.length) {
    select +=
      "," +
      constructSupabaseSelect({
        columns: additionalColumns,
        baseTableName: "pages"
      });
  }

  const response = await supabaseClient
    .from("ui_menus")
    .select(select)
    .eq("is_deleted", false)
    .eq("is_global", true)
    .eq("workspace_id", workspaceId)
    .in("user_type", [userType, "All"])
    .maybeSingle();

  return response as unknown as MenuResponse;
};

export const getRecordMenu = async (
  supabaseClient: SupabaseClient,
  userType: string,
  pagePath: string,
  workspaceId?: string
): Promise<MenuResponse> => {
  const { data, error } = await supabaseClient
    .from("ui_menus")
    .select(
      `*, 
        ui_menu_items!inner(*,
          menuItemRoles:ui_menu_items_roles(id,role_id), 
          ui_page_id(id, path, title, default_view, mobile_default_view, table_name, metabase_dashboard_id, page_type, is_admin_page), 
          ui_menu_actions(id,  menuActionRoles:ui_menu_actions_roles(id,role_id), action_properties, wait_for_success, has_divider, sort, 
            ui_action_id(name, icon, type))),
          pages:pages!pages_menu_id_fkey!inner(id,table_name, path,
            views:ui_pages_views(id,default_section_id,record_default_section_id,is_custom,view:views_id(*), pageViewRoles:ui_pages_views_roles(id,role_id),
              conditionalFormatting:ui_column_rules(id, rule_config, 
                columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)), 
                tabs:ui_pages_views_tabs(id,tab:ui_tab_id(tab_page_id(path),ui_menu_item_id))))`
    )
    .eq("is_deleted", false)
    .eq("is_global", false)
    .in("pages.path", [pagePath])
    .in("user_type", [userType, "All"])
    .eq("ui_menu_items.is_deleted", false)
    .eq("ui_menu_items.workspace_id", workspaceId)
    .eq("workspace_id", workspaceId)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true })
    .maybeSingle();

  return { data, error };
};

export const getAllPageViews = async (supabaseClient: SupabaseClient, id?: string, workspaceIds?: string[]) => {
  if (!id || !workspaceIds?.length) return Promise.resolve({ data: undefined, error: null });
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `
  id,path,is_deleted,head_title,page_type,is_default_table_page,
  views:ui_pages_views(id,pageViewRoles:ui_pages_views_roles(id,role_id),conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),default_section_id,record_default_section_id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*))
  `
    )
    .eq("id", id)
    .in("workspace_id", workspaceIds);
  if (!data?.[0]) return Promise.resolve({ data: undefined, error });

  // @ts-ignore
  const finalPageData = mapApiPageToPage(data[0]);
  return { data: finalPageData, error: null };
};

export const getPageViewAndColumns = async ({
  supabaseClient,
  viewType,
  pageSlug,
  userType,
  id,
  workspaceIds
}: {
  supabaseClient: SupabaseClient;
  viewType: ViewOption;
  pageSlug: string;
  userType: USER_TYPE;
  id?: string;
  workspaceIds?: string[];
}) => {
  if (!pageSlug || !viewType || !workspaceIds?.length) return Promise.resolve({ data: undefined, error: null });

  let supabaseRef = supabaseClient
    .from("pages")
    .select(
      `
    id,is_deleted,page_config,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,mobile_default_view,page_type,parent_id,path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,static_content,table_name,title,user_type,
    columns:ui_pages_columns(*,
      lookupFilters:lookup_filter_id(id,
        columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null,filter_db_type)
      ),
      column:columns_id(*,
        ui_pages_views_columns(*, view:pages_views_id(id,pages_id,views_id(id,view_type,is_default, is_deleted)))
      )
    ),
    views:ui_pages_views(id,default_section_id,record_default_section_id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*),pageViewRoles:ui_pages_views_roles(id,role_id),
      conditionalFormatting:ui_column_rules(id, rule_config, columnRules:ui_column_condition(id,group_condition_id,ui_page_column_id,field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      tabs:ui_pages_views_tabs(id,page_view_id,created_at,is_child,is_hidden,
        tab:ui_tabs(
          id,
          tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          sort,
          visibility,
          ui_menu_item_id,
          default_add_expanded,
          show_record_count
        )
      ))`
    )
    .eq("views.view.is_deleted", false)
    .eq("views.view.view_type", viewType)
    .eq("user_type", userType)
    .in("workspace_id", workspaceIds);

  if (id) {
    supabaseRef = supabaseRef.eq("id", id);
  } else {
    supabaseRef = supabaseRef.eq("path", pageSlug?.startsWith("/") ? pageSlug : `/${pageSlug}`);
  }
  const { data: pageData, error } = await supabaseRef;
  if (!pageData?.[0]) return Promise.resolve({ data: undefined, error });

  // @ts-ignore
  const finalPageData = mapApiPageToPage(pageData[0]);
  return { data: finalPageData, error: null };
};

export const getPageActionsByPathOrId = async ({
  supabaseClient,
  pageId,
  pagePath,
  userType,
  excludePageDetails,
  workspaceIds
}: {
  supabaseClient: SupabaseClient;
  userType: USER_TYPE;
  pageId?: string;
  pagePath?: string;
  excludePageDetails?: boolean;
  workspaceIds?: string[];
}) => {
  if ((!pageId && !pagePath) || !workspaceIds?.length) return Promise.resolve({ data: undefined, error: null });
  let baseSelect = `id,ui_pages_actions(id,ui_views_id,section_id,section:section_id(id,title,type,config,sort),actionRoles:ui_pages_actions_roles(id, role_id), ui_action_id(id, name, type, icon, requires_confirmation, confirmation_color, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)))`;

  if (!excludePageDetails) {
    baseSelect +=
      ",is_deleted,page_config,default_view,default_empty_view,is_admin_page,mobile_default_view,page_type,path,is_default_table_page,skip_record_sidebar_view,sort,table_name,title,user_type,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))";
  }
  let supabaseRef = supabaseClient
    .from("pages")
    .select(baseSelect)
    .eq("user_type", userType)
    .in("workspace_id", workspaceIds);

  if (pageId) {
    supabaseRef = supabaseRef.eq("id", pageId);
  } else if (pagePath) {
    supabaseRef = supabaseRef.eq("path", pagePath.startsWith("/") ? pagePath : `/${pagePath}`);
  }

  const { data: pageData, error } = await supabaseRef;
  if (!pageData?.[0]) return Promise.resolve({ data: undefined, error });

  return { data: pageData[0] as RecordItem, error: null };
};

export const getBASchema = async (resetCache?: boolean, baseUrl?: string) => {
  let options: { headers?: RecordItem; next?: RecordItem } = {};

  if (resetCache) {
    options = {
      headers: {
        "ba-cache": "reset"
      },
      next: { revalidate: 0 }
    };
  } else {
    options = {
      next: { revalidate: 7200 }
    };
  }
  try {
    const result = await fetch(`${baseUrl || "https://staff.soldave.com"}/api/admin/ba_schema`, { ...options }).then(
      (res) => res.json()
    );
    return result;
  } catch (err) {
    console.log("🚀 ~ getBASchema ~ error fetching ba Schema", err);
  }
  return undefined;
};

export const getBASchemaKvCache = async (url?: string) => {
  try {
    return getBASchema(false, url);
  } catch (error) {
    console.log("🚀 ~ getBASchemaKvCache ~ error:", error);
  }

  return null;
};

export const getRecordTypes = async (supabaseClient: SupabaseClient): Promise<RecordTypesResponse> => {
  const { data, error } = await supabaseClient
    .from("record_types")
    .select(
      "id,type,tablename,page_id,config,schema,page:page_id(id,path,title,table_name),lookup_column(id,lookup_path),image_column(id,lookup_path,page_columns:ui_pages_columns(id,page_id,lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))))"
    );

  return {
    data: data?.map((recordType) => ({
      ...recordType,
      image_column: recordType?.image_column
        ? {
            ...recordType?.image_column,
            lookup_filters: (recordType?.image_column as RecordItem)?.page_columns?.find(
              (col: RecordItem) => col.page_id === recordType.page_id
            )?.lookupFilters
          }
        : undefined
    })) as RecordItem[] as ApiRecordType[],
    error
  };
};

export const getAllCustomViews = async (
  supabaseClient: SupabaseClient,
  workspaceIds?: string[]
): Promise<CustomViewsResponse> => {
  if (!workspaceIds?.length) return { data: [], error: null };
  const { data, error } = await supabaseClient
    .from("ui_views")
    .select(
      "id,title,view_type,viewRoles:ui_views_roles(id,role_id),columns:ui_views_columns(id,sort,column:columns_id(id,header,name,is_lookup,is_static))"
    )
    .eq("is_deleted", false)
    .eq("is_custom", true)
    .in("workspace_id", workspaceIds);

  return {
    data: data?.length
      ? data.map((viewData) => mapApiViewToTableViewConfig(viewData as RecordItem as ApiView, true))
      : [],
    error
  };
};

export const getCustomViewById = async (
  supabaseClient: SupabaseClient,
  id?: string,
  workspaceId?: string
): Promise<TableViewConfigResponse> => {
  if (!id || !workspaceId) return { data: undefined, error: null };

  const { data, error } = await supabaseClient
    .from("ui_views")
    .select(
      `*,viewRoles:ui_views_roles(id,role_id),
      columns:ui_views_columns(id,sort,is_deleted,
        column:columns_id(*,
        conditionalViewRules:conditional_view_rule(id,rule_config,
          columnRules:ui_column_condition(id,group_condition_id,viewColumn:ui_view_column_id(*),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
        ),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        pageColumn:pages_columns_id(id,page_id,is_deleted,
          columnFilters:column_options_filter_id(id,
            columnRules:ui_column_condition(id,group_condition_id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,
              value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)
            )
          )
      ),
      sections:ui_sections(id,
        sectionRoles:ui_sections_roles(id,role_id),
        title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        viewColumns:ui_views_columns_sections(id,
          ui_views_columns_id(columns_id)),
          filter:ui_filters_id(id,
            columnRules:ui_column_condition(id,group_condition_id,
              colConditionRoles:ui_col_condition_roles(id,role_id,is_hidden),
              pageColumn:ui_page_column_id(*,is_deleted,
                column:columns_id(is_deleted,id)
              ),
              field,operator,filter_db_type,value,is_user_visible,option_value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          ),
          conditionalViewRules:conditional_view_rule_id(id,rule_config,
            columnRules:ui_column_condition(id,group_condition_id,
              viewColumn:ui_view_column_id(*),
              field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          )
        )
      )`
    )
    .eq("id", id)
    .eq("workspace_id", workspaceId);
  // @ts-ignore
  return { data: mapApiViewToTableViewConfig(data?.[0] as ApiView, true), error };
};

export const getValidWorkspaceIdsForUserId = async (
  supabaseClient: SupabaseClient,
  userId: string
): Promise<{ data: null | string[]; error: PostgrestError | null }> => {
  if (!userId) {
    return { data: null, error: null };
  }

  const { data, error } = await supabaseClient.rpc("getworkspaceidsforuserrpc", {
    contact_id: userId
  });
  if (error) {
    return { data: null, error };
  }
  const workspaceIds = data?.map((workspace: RecordItem) => workspace.workspace_id);

  return { data: workspaceIds, error };
};

export const getAllColors = async (
  supabaseClient: SupabaseClient,
  workspaceIds?: string[],
  skipWorkspaceIdCheck?: boolean
): Promise<ColorsResponse> => {
  if (!workspaceIds?.length && !skipWorkspaceIdCheck) {
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient.from("colors").select();
  if (!skipWorkspaceIdCheck && workspaceIds?.length) {
    supabaseSelectRef = supabaseSelectRef.in("workspace_id", workspaceIds);
  }
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const getCustomIcons = async (
  supabaseClient: SupabaseClient,
  workspaceIds?: string[],
  skipWorkspaceIdCheck?: boolean
): Promise<CustomIconResponse> => {
  if (!workspaceIds?.length && !skipWorkspaceIdCheck) {
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient.from("icons").select("*,icon(id,name,path,file_type)");
  if (!skipWorkspaceIdCheck && workspaceIds?.length) {
    supabaseSelectRef = supabaseSelectRef.in("workspace_id", workspaceIds);
  }
  supabaseSelectRef = supabaseSelectRef.eq("is_deleted", false);
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const getAllWorkspaces = async (supabaseClient: SupabaseClient) => {
  const { data, error } = await supabaseClient.from("workspaces").select("*");
  return { data, error };
};

export const getAllRoles = async (supabaseClient: SupabaseClient, filters?: { roleType?: string }) => {
  let supabaseRef = supabaseClient.from("roles").select("*");
  if (filters?.roleType) {
    supabaseRef = supabaseRef.eq("role_type", filters.roleType);
  }
  const { data, error } = await supabaseRef;
  return { data, error };
};

export const getPageSectionsById = async ({
  supabaseClient,
  pageId,
  workspaceIds
}: {
  supabaseClient: SupabaseClient;
  pageId?: string;
  workspaceIds?: string[];
}): Promise<{
  data?: ViewSection[];
  error?: PostgrestError | null;
}> => {
  if (!pageId || !workspaceIds?.length) return Promise.resolve({ data: undefined, error: null });
  const { data, error } = await supabaseClient
    .from("ui_sections")
    .select("*")
    .eq("page_id", pageId)
    .in("workspace_id", workspaceIds);

  return {
    data: mapApiColumnSectionsToSections(data as ApiColumnSection[]),
    error
  };
};

export const getQuizBySlug = async (
  supabaseClient: SupabaseClient,
  slug: string,
  onlyFetchMetadata?: boolean
): Promise<{
  data?: Quiz;
  error?: PostgrestError | null;
}> => {
  if (!slug) return Promise.resolve({ data: undefined, error: null });
  let select;
  if (onlyFetchMetadata) {
    select = "*";
  } else {
    select = "*,questions:quiz_questions(*)";
  }
  let supabaseRef: any = supabaseClient.from("quizzes").select(select).eq("slug", slug).eq("is_deleted", false);

  if (!onlyFetchMetadata) {
    supabaseRef = supabaseRef.order("sort_order", { referencedTable: "quiz_questions", ascending: true });
  }
  supabaseRef = supabaseRef.limit(1).single();
  const { data, error } = await supabaseRef;
  return { data: mapApiQuizToQuiz(data as any as ApiQuiz), error };
};

export const getSACachedValue = async (key: string, supabaseClient: SupabaseClient) => {
  if (!key) return Promise.resolve(undefined);
  const result = await supabaseClient.from("_soldave_cache").select("value").eq("key", key).maybeSingle();

  return result;
};

export const updateCacheValue = async (key: string, value: any, supabaseClient: SupabaseClient) => {
  if (!key) return Promise.resolve(undefined);
  const result = await supabaseClient
    .from("_soldave_cache")
    .upsert({ key, value, updated_at: new Date().toISOString() });

  return result;
};

// This method deletes all keys starting with passed key value
export const removeCacheKeysByPartialKey = async (key: string, supabaseClient: SupabaseClient) => {
  if (!key) return Promise.resolve(undefined);
  const result = await supabaseClient.from("_soldave_cache").delete().like("key", `${key}%`);

  return result;
};

// This method only deletes the key passed
export const removeCacheKey = async (key: string, supabaseClient: SupabaseClient) => {
  if (!key) return Promise.resolve(undefined);
  const result = await supabaseClient.from("_soldave_cache").delete().eq("key", key);

  return result;
};
